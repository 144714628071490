<template>
  <v-card elevation="0">
    <v-btn block tile class="primary" @click="addBook">{{
      $t("page.profileContributions.addBook")
    }}</v-btn>
    <v-card-text v-if="hasContributedBooks">
      <v-row>
        <v-col
          md="4"
          sm="12"
          v-for="book in contributedBooks"
          :key="book.publicId"
        >
          <book-card
            :book="book"
            @click="navigateTo(book.publicId)"
          ></book-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <v-row> NO BOOK </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import bookCard from "/components/Library/BookCard";
import * as Routes from "#Routes";
export default {
  props: ["userId"],
  components: { bookCard },
  data() {
    return {
      books: null,
      loading: true,
    };
  },
  computed: {
    contributingUserId() {
      return this.userId != null
        ? this.userId
        : this.$store.getters["auth/userId"];
    },
    hasContributedBooks() {
      return this.contributedBooks != null && this.contributedBooks.length > 0;
    },
    contributedBooks() {
      return this.books;
    },
    toBooks() {
      return Routes.BOOK;
    },
  },
  watch: {},
  methods: {
    navigateTo(publicId) {
      this.$router.push({ name: this.toBooks, params: { bookId: publicId } });
    },
    addBook() {
      //alert("Add book");
      this.$store.dispatch("notification/showBookForm", {});
      //   this.$store.dispatch("notification/addModal", {
      //     componentName: "book-form",
      //     fullscreen: true,
      //   });
    },
  },
  async created() {
    this.loading = true;
    this.$store
      .dispatch("user/contributedBooks", this.contributingUserId)
      .then((res) => {
        this.books = res;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>